* {
    box-sizing: border-box;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

#content {
}

ul {
    padding: 0;
}
