// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$tieple_web_UI-primary: mat.define-palette(mat.$indigo-palette);
$tieple_web_UI-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tieple_web_UI-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tieple_web_UI-theme: mat.define-light-theme(
  (
    color: (
      primary: $tieple_web_UI-primary,
      accent: $tieple_web_UI-accent,
      warn: $tieple_web_UI-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tieple_web_UI-theme);
@import "assets/styles/reset";
@import "assets/styles/typography";
@import "assets/styles/color";
@import "assets/styles/button";

/* Tieple Font */
// $tieple-custom-typography: mat.define-typography-config(
//     $display-4: mat.define-typography-level(112px, 112px, 300, $font-family-primary, $letter-spacing: -0.05em),
//     $display-3: mat.define-typography-level(56px, 56px, 400, $font-family-primary, $letter-spacing: -0.02em),
//     $display-2: mat.define-typography-level(45px, 48px, 400, $font-family-primary, $letter-spacing: -0.005em),
//     $display-1: mat.define-typography-level(34px, 40px, 400, $font-family-primary),
//     $headline: mat.define-typography-level(24px, 32px, 400, $font-family-accent),
//     $title: mat.define-typography-level(20px, 32px, 500, $font-family-primary),
//     $subheading-2: mat.define-typography-level(16px, 28px, 400, $font-family-primary),
//     $subheading-1: mat.define-typography-level(15px, 24px, 400, $font-family-accent),
//     $body-2: mat.define-typography-level(14px, 24px, 500, $font-family-primary),
//     $body-1: mat.define-typography-level(14px, 20px, 400, $font-family-primary),
//     $caption: mat.define-typography-level(12px, 20px, 400, $font-family-primary),
//     $button: mat.define-typography-level(14px, 14px, 500, $font-family-primary),
//     $input: mat.define-typography-level(inherit, 1.125, 400, $font-family-primary),
// );
// @include mat.core($tieple-custom-typography);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
  background-color: $white;
}

.mat-dialog-container {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #cacaca;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fdda5a;
    border-radius: 20px;

    &:hover {
      background-color: #ffc800;
    }
  }
}

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

.swiper {
  width: 100%;
  height: 100%;
  &-wrapper {
    align-items: center;
  }

  &-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

[class*="dashboard__mentor-community-content-swiper"] {
  .swiper-button-prev,
  .swiper-button-next {
    top: 47%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    background-color: $white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: $indigo;

    &::after {
      font-size: medium;
      font-weight: 600;
    }
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .swiper-button-prev {
      left: -18px;
    }

    .swiper-button-next {
      right: -18px;
    }
  }
}

[class*="dashboard__mnc-frame-content-swiper"] {
  padding: 0 32px;

  .swiper-slide {
    &:nth-child(1n) > div {
      background-color: rgba(41, 131, 209, 0.251);
      width: 160px;
      height: 160px;
    }

    &:nth-child(2n) > div {
      background-color: rgba(245, 202, 46, 0.251);
      width: 140px;
      height: 140px;
    }

    &:nth-child(3n) > div {
      background-color: rgba(210, 40, 136, 0.251);
      width: 140px;
      height: 140px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 43%;
    background: #85858a40;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    color: #696f77;

    &::after {
      font-size: xx-large;
      font-weight: 600;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding: 0 12px;

    .swiper-slide {
      &:nth-child(1n) > div {
        width: 90px;
        height: 90px;
      }

      &:nth-child(2n) > div,
      &:nth-child(3n) > div {
        width: 78px;
        height: 78px;
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 52%;
      width: 40px;
      height: 40px;

      &::after {
        font-size: medium;
      }
    }
  }
}

.swiper-pagination {
  right: 121px !important;
  top: 65% !important;
}

.swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  margin: 20px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  background-color: $white;
}

.swiper-pagination-bullet-active {
  width: 24px;
  height: 74px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #f5ca2e;
  overflow: visible;
  border-radius: 50px;
}

.mat-tab-group {
  font-family: $font-family-primary;
}

[class*="dashboard__courses-card-button"],
[class*="dashboard__candidate-content-button"],
[class*="dashboard__carousel-swiper-item-content-btn"],
[class*="dashboard__program-card-button"] {
  button {
    width: inherit;
  }
}

[class*="for-students"] {
  &.mat-menu-panel {
    max-width: 600px;
    position: absolute;
    top: 10px;

    .mat-menu-content {
      &:not(:empty) {
        min-width: 600px;
        padding: 20px 12px;
      }

      .arrow {
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #f5ca2e transparent;
      }

      .top-content {
        display: flex;
        column-gap: 12px;

        .left-content,
        .right-content {
          width: 50%;
        }

        .left-content {
          .courses-title-wrap {
            margin-bottom: 8px;

            .courses-icon {
              width: 38px;
              height: 38px;
            }
          }

          .mat-menu-item {
            height: auto;
            min-height: 30px;
            line-height: 20px;
            border-radius: 4px;
            padding: 4px 6px;
            font-family: $ff-primary-medium;
            font-size: 12px;
            white-space: normal;
            display: flex;
            align-items: center;
            color: $indigo;

            img {
              width: 18px;
              height: 18px;
              transform: rotate(270deg);
              margin-right: 4px;
            }
          }
        }

        .right-content {
          .mat-menu-item {
            font-family: $ff-primary-medium;
            border-radius: 4px;
            padding: 4px 10px;
            display: flex;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
          }
        }
      }

      .bottom-content {
        h5 {
          padding-left: 10px;
        }

        .student-actions {
          display: flex;

          .mat-menu-item {
            font-family: $ff-primary-medium;
            border-radius: 4px;
            padding: 4px 10px;
            display: flex;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

[class*="for-entrepreneurs"],
[class*="for-mentors"],
[class*="for-colleges"] {
  &.mat-menu-panel {
    max-width: 300px;
    position: absolute;
    top: 10px;

    .mat-menu-content {
      &:not(:empty) {
        min-width: 300px;
        padding: 20px 12px;
      }

      .left-content {
        .courses-title-wrap {
          margin-bottom: 8px;
          display: flex;
          align-items: center;

          .courses-title {
            margin: 0;
          }

          .courses-icon {
            width: 38px;
            height: 38px;
          }
        }

        .mat-menu-item {
          font-family: $ff-primary-medium;
          border-radius: 4px;
          padding: 4px 10px;
          display: flex;
          align-items: center;
          white-space: normal;
          line-height: normal;

          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

[class*="job-calculator-modal"] {
  .swiper {
    .question-options-container {
      .options {
        .mat-form-field {
          width: 80px;

          &.lg-textfield {
            max-width: 250px;
            width: 100%;
          }
        }

        .mat-list-base {
          .mat-list-item {
            .mat-list-item-content {
              justify-content: space-evenly;

              .kc-exp {
                height: 32px;
                max-width: 250px;
                width: 100%;
                text-align: left;
              }

              .mat-form-field {
                width: 150px;
              }
            }
          }
        }
      }
    }
  }
}

[class*="login-modal"] {
  @media only screen and (max-width: 600px) {
    max-width: 100% !important;
    max-height: 100% !important;

    .mat-dialog-container {
      border-radius: 0;
      .login-dialog-container {
        height: 100%;

        .asset-section {
          display: none;
        }
      }
    }
  }
}

// Vertical Stepper
.mat-step-text-label,
.step-label {
  font-family: $ff-primary-medium !important;
  font-size: $fs14 !important;
  color: $liberty;
  margin-bottom: 0px !important;
}

.mat-step-label-selected {
  .mat-step-text-label,
  .step-label {
    font-family: $ff-primary-semibold !important;
    font-size: $fs20 !important;
    line-height: $lh-2;
  }
}

.mat-step-icon-state-done {
  height: 36px !important;
  width: 36px !important;
  left: -5px;
}

.dashboard__approach-content-slots {
  .mat-chip-list-wrapper {
    column-gap: 10px;
    row-gap: 8px;
  }
}

[class*="job-calculation-overlay"] {
  max-width: 100% !important;

  .mat-dialog-container {
    border-radius: 0;
    box-shadow: none;
  }
}

[class*="selection-process__tabs-container"] {
  .mat-tab-label {
    height: 108px;

    .mat-tab-label-content {
      flex-direction: column;

      .tab-icon-wrap {
        margin-bottom: 4px;
        
        .mat-icon {
          font-size: 36px;
          height: 36px;
          width: 36px;
        }
      }
      
    }
  }
}

@media screen and (max-width: 1920px) {
  .swiper-pagination {
    right: 294px !important;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
  }
  .swiper-pagination-bullet-active {
    width: 20px;
    height: 62px;
  }
}

@media screen and (max-width: 1600px) {
  .swiper-pagination {
    right: 104px !important;
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
  }
  .swiper-pagination-bullet-active {
    width: 18px;
    height: 52px;
  }
}

@media screen and (max-width: 1280px) {
  .swiper-pagination {
    top: unset !important;
    right: unset !important;
  }
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 5px !important;
  }
  .swiper-pagination-bullet-active {
    width: 36px;
    height: 12px;
  }
}
