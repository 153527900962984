/* Default App Colors */

/*App Dashboard*/
$appTextColor: #1f1e1e;

/*App Logo*/
$appLogoTextColor: #1a1919;

/*Navbar*/
$appNavbarTextColor: #1f1e1e;
$appNavbarTextSelectedColor: #6161cc;

/* Home */
$homeBannerCardTitleTextColor: #000000;
$homeBannerCardSubtitleTextColor: #696f77;
$homeBannerCardYellowMobileBackgroundColor: #fff8e0;
$homeBannerCardBlueMobileBackgroundColor: #e5f3ff;
$homeBannerCardRedMobileBackgroundColor: #ffe0ec;

$homePaymentTitleColor: #312f2f;
$homePaymentSubtitleColor: #5151bd;

/*Card*/
$appCardColor: #e8dcac;

/*Journey Forever*/
$journeyForeverBackgroundColor: #f2f1e8;

/*Top Trends*/
$topTrendsBackgroundColor: #f2f1e8;

/*User guide*/
$userGuideBackgroundColor: #f8e9b9;
$userGuideIconColor: #ec7d33;

/*Explore Courses*/
$exploreCoursesBackgroundColor: #e0dcdc;

/*Footer*/
$appFooterBackgroundColor: #a6a4a4;


// Global Colors
$argent: #bfbfbf;
$brightNavyBlue: #2983d1;
$black: #000000;
$blackOlive: #3b3d40;
$charlestonGreen: #2b2b2b;
$cultured: #f6f4f4;
$deepCerise: #d22888;
$darkliver: #535050;
$darkCharcoal: #312f2f;
$indigo: #202064;
$lightSilver: #d9d9d9;
$maxBluePurple: #a1a1ed;
$magnolia: #f5f5ff;
$nickel: #696f77;
$oceanBlue: #5151BD;
$quickSilver: #a0a3a6;
$saffron: #f5ca2e;
$silverPink: #c5b0b0;
$spanishGray: #969696;
$white: #ffffff;
$liberty: #5c5cad;
$slateBlue: #6161cc;
$green:#66ff66;

// Backgrounds
.pink-bg {
    background-color: $deepCerise;
}

.pink-text {
    color:$deepCerise;
}

.saffron-bg {
    background-color: $saffron;
}

.ocean-blue-bg {
    background-color: $oceanBlue;
}

.bright-navy-blue-bg {
    background-color: $brightNavyBlue;
}

// Text or Links
.ocean-blue-txt {
    color: $oceanBlue;
}

.dark-liver {
    color: $darkliver;
}

.bright-navy-blue {
    color: $brightNavyBlue;
}

// Borders
.pink-border {
    border-color: $deepCerise;
    &-r {
        border-right-color: $deepCerise;
    }

    &-l {
        border-left-color: $deepCerise;
    }

    &-t {
        border-top-color: $deepCerise;
    }

    &-b {
        border-bottom-color: $deepCerise;
    }
}

.saffron-border {
    border-color: $saffron;
    &-r {
        border-right-color: $saffron;
    }

    &-t {
        border-top-color: $saffron;
    }
}

.ocean-blue-border {
    border-color: $brightNavyBlue;
    &-r {
        border-right-color: $brightNavyBlue;
    }
}
