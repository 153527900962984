a {
    font-family: $ff-primary-medium;
    font-size: $fs12;
    text-decoration: none;
    color: $oceanBlue;

    &.regular {
        font-family: $font-family-primary;
    }

    &.md {
        font-family: $font-family-primary;
        font-size: $fs14;
    }

    &:hover {
        color: $indigo;
    }

    &.light {
        color: $white;
    }
}
