@font-face {
    font-family: "PoppinsRegular";
    src: url("../fonts/Poppins/Poppins-Regular.woff") format("woff"), /* Pretty Modern Browsers */
    url("../fonts/Poppins/TrueType/Poppins-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "PoppinsMedium";
    src: url("../fonts/Poppins/Poppins-Medium.woff") format("woff"), /* Pretty Modern Browsers */
    url("../fonts/Poppins/TrueType/Poppins-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.woff") format("woff"), /* Pretty Modern Browsers */
    url("../fonts/Poppins/TrueType/Poppins-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../fonts/Poppins/Poppins-Bold.woff") format("woff"), /* Pretty Modern Browsers */
    url("../fonts/Poppins/TrueType/Poppins-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
    font-family: "MontserratRegular";
    src: url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"); /* Pretty Modern Browsers */
}

@font-face {
    font-family: "MontserratMedium";
    src: url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff"); /* Pretty Modern Browsers */
}

@font-face {
    font-family: "MontserratSemiBold";
    src: url("../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"); /* Pretty Modern Browsers */
}

@font-face {
    font-family: "MontserratBold";
    src: url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"); /* Pretty Modern Browsers */
}

@import "variables";
@import "color";

// Headings
h1,
.h1 {
    font-family: $ff-primary-semibold !important;
    font-size: $fs36 !important;
    line-height: $lh-3 !important;
    &.lg {
        font-size: $fs80 !important;
    }

    &.secondary {
        font-family: $ff-accent-medium !important;
        font-size: $fs72 !important;
    }
}

h2,
.h2 {
    font-family: $ff-primary-semibold !important;
    font-size: $fs28 !important;
    line-height: $lh-3 !important;
    &.lg {
        font-family: $ff-primary-medium !important;
        font-size: $fs72 !important;
    }

    &.secondary {
        font-family: $ff-accent-medium !important;
        font-size: $fs64 !important;
    }
}

h3,
.h3 {
    font-family: $ff-primary-semibold !important;
    font-size: $fs20 !important;
    &.lg {
        font-family: $ff-primary-medium !important;
        font-size: $fs64 !important;
        line-height: $lh-2 !important;
    }

    &.secondary {
        font-family: $ff-accent-semibold !important;
        font-size: $fs48 !important;
        line-height: $lh-3 !important;
    }
}

h4,
.h4 {
    font-family: $ff-primary-semibold !important;
    font-size: $fs18 !important;
    line-height: $lh-2 !important;
    &.lg {
        font-family: $ff-primary-medium !important;
        font-size: $fs40 !important;
        line-height: $lh-3 !important;
    }

    &.secondary {
        font-family: $ff-accent-medium !important;
        font-size: $fs24 !important;
    }
}

h5,
.h5 {
    font-family: $ff-primary-semibold !important;
    font-size: $fs16 !important;
    &.lg {
        font-size: $fs32 !important;
        line-height: $lh-3 !important;
    }

    &.secondary {
        font-family: $ff-accent-medium !important;
        font-size: $fs20 !important;
        line-height: $lh-2 !important;
    }
}

h6,
.h6 {
    font-family: $ff-primary-medium !important;
    font-size: $fs14 !important;

    &.secondary {
        font-family: $ff-accent-medium !important;
        font-size: $fs16 !important;
    }
}

// Paragraphs
p {
    font-family: $font-family-primary !important;
    font-size: $fs12 !important;
    line-height: $lh-2 !important;
    &.p1 {
        font-size: $fs28 !important;
        * {
            font-size: inherit !important;
        }
    }

    &.p2 {
        font-size: $fs24 !important;
        * {
            font-size: inherit !important;
        }
    }

    &.p3 {
        font-size: $fs18 !important;
        * {
            font-size: inherit !important;
        }
    }

    &.p4 {
        font-size: $fs16 !important;
        * {
            font-size: inherit !important;
        }
    }

    &.p5 {
        font-size: $fs14 !important;
        &-md {
            font-family: $ff-primary-medium !important;
        }
        * {
            font-size: inherit !important;
        }
    }
    &.md {
        font-family: $ff-primary-medium !important;
    }

    &.sh-2 {
        font-family: $ff-primary-medium !important;
        font-size: $fs28 !important;
    }
}

// List
ul {
    font-family: $font-family-primary;
    font-size: $fs12;

    &.md {
        font-family: $ff-primary-medium;
        font-size: $fs14;
    }

    &.lg {
        font-size: $fs16;
    }
}

// Containers
div {
    font-family: $font-family-primary !important;
}

.answer-section {
    .answer-list {
        font-size: $fs14;
        margin-left: 20px;
        margin-top: 8px;
    }
}
