// Font family
$font-family-primary: "PoppinsRegular", sans-serif;
$ff-primary-medium: "PoppinsMedium", sans-serif;
$ff-primary-semibold: "PoppinsSemiBold", sans-serif;
$ff-primary-bold: "PoppinsBold", sans-serif;

$font-family-accent: "MontserratRegular", sans-serif;
$ff-accent-medium: "MontserratMedium", sans-serif;
$ff-accent-semibold: "MontserratSemiBold", sans-serif;
$ff-accent-bold: "MontserratBold", sans-serif;

// Font size
$fs80: 80px;
$fs72: 72px;
$fs64: 64px;
$fs48: 48px;
$fs40: 40px;
$fs38: 38px;
$fs36: 36px;
$fs32: 32px;
$fs30: 30px;
$fs28: 28px;
$fs24: 24px;
$fs20: 20px;
$fs18: 18px;
$fs16: 16px;
$fs14: 14px;
$fs12: 12px;

// Line height
$lh-1: 1em;
$lh-2: 1.4em;
$lh-3: 1.2em;